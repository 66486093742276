export const color = {
  DARK_GRAY: "#232424",
  GRAY: "#ECECEC",
  LIGHT_GREY: "#E7E8E8",
  PALE_GREY: "#F6F7F7",
  BLUE_GREY: "#4F5461",
  MID_GREY: "hsl(0 0% 50%)",
  GOLD: "#E0BF64",
  CHARCOAL_BLUE: "#19262E",
  LIGHT_CHARCOAL_BLUE: "#2b3b45",
  PALE_GREEN: "#EEF1EA",
  PURPLE: "#d6446e",
};

export const tokens = {
  CARD_COLOR: "white",
  BACKGROUND_COLOR: color.PALE_GREY,
  BUTTON_COLOR: color.LIGHT_CHARCOAL_BLUE,
  BUTTON_LABEL_COLOR: "white",
  SUBTLE_BUTTON_COLOR: "#DEDEDE",
};

export const zIndex = {
  HEADER_NAV: 1,
  MENU: 2,
  SIDEBAR: 3,
  SPINE: 4,
  DIALOG: 5,
  POPOVER: 6,
};
