import { useDialogStore } from "@ariakit/react";
import { useEffect } from "react";
import { useLocation } from "wouter";

export function useDialog(props?: { initialIsOpen?: boolean }) {
  const store = useDialogStore({
    animated: true,
    defaultOpen: props?.initialIsOpen,
  });

  // Close dialog whenever pathname changes
  const [pathname] = useLocation();
  useEffect(() => {
    store.hide();
  }, [store, pathname]);

  const isAnimating = store.useState("animating");
  const isOpen = store.useState("open");
  const isOpenOrOpening = isAnimating || isOpen;

  return {
    isOpen: isOpenOrOpening,
    setOpen: store.show,
    setClosed: store.hide,
    toggleOpen: isOpenOrOpening ? store.hide : store.show,
    store,
  };
}
