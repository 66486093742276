import { BattlePage } from "@/battle/battle-page";
import { useScrollRestoration } from "@/ui/hooks/use-scroll-to-top";
import { RootLayout } from "@/ui/root-layout";
import { DashboardPage } from "@/ui/dashboard-page";
import { Suspense } from "react";
import { Route, Switch } from "wouter";
import { AccountPage } from "../account-view";
import { ArmiesPage } from "../armies-page";
import { ArmyPages } from "../army-detail-page";
import { ChangelogPages } from "../changelog";
import { FortunePage } from "../fortune/fortune-page";
import { HelpPages } from "../help";
import { LoadingIndicator } from "../loading-indicator";
import { MainLayout } from "../main-layout";
import { PrintArmyPage } from "../print-army-page";
import { PurchasePage } from "../purchase-page";
import { QuickStartKeywords } from "../quick-start-keywords";
import { QuickStartPage } from "../quick-start-page";
import { UserSettingsPage } from "../settings-page";
import { SharedArmyPages } from "../shared-army-detail-page";
import { TargetNumbers } from "../target-numbers";
import { UnauthenticatedPage } from "../unauthenticated-page";
import { UnitProfiles } from "../unit-profiles";

export function App() {
  useScrollRestoration();

  return (
    <RootLayout>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route path="/unauthenticated" component={UnauthenticatedPage} />

          <Route path="/print/a/:armyId" component={PrintArmyPage} />

          <Route>
            <MainLayout>
              <Switch>
                <Route path="/" component={DashboardPage} />

                <Route path="/army" component={ArmiesPage} />

                <Route path="/army/a/:armyId" nest>
                  <ArmyPages />
                </Route>

                <Route path="/army/s/:shareId" nest>
                  <SharedArmyPages />
                </Route>

                <Route path="/battle" component={BattlePage} />

                <Route path="/fortune" component={FortunePage} />

                <Route path="/quick-start" nest>
                  <Switch>
                    <Route path="/" component={QuickStartPage} />
                    <Route path="/keywords" component={QuickStartKeywords} />
                    <Route path="/profiles" component={UnitProfiles} />
                    <Route path="/target-numbers" component={TargetNumbers} />
                    <Route path="/purchase" component={PurchasePage} />
                  </Switch>
                </Route>

                <Route path="/settings" component={UserSettingsPage} />

                <Route path="/account" component={AccountPage} />

                <Route path="/changelog" nest>
                  <ChangelogPages />
                </Route>

                <Route path="/help" nest>
                  <HelpPages />
                </Route>
              </Switch>
            </MainLayout>
          </Route>
        </Switch>
      </Suspense>
    </RootLayout>
  );
}
