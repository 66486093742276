import { AnchorHTMLAttributes, ForwardedRef, forwardRef } from "react";
import { Link as WouterLink } from "wouter";

export const Link = forwardRef(function Link(
  props: AnchorHTMLAttributes<HTMLAnchorElement> & { href: string },
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const { href, onClick, ...rest } = props;

  if (/^[/~]/.test(props.href)) {
    return (
      <WouterLink href={href} onClick={onClick} asChild>
        <a {...rest} ref={ref} />
      </WouterLink>
    );
  }

  return <a {...rest} ref={ref} target="_blank" rel="noreferrer noopener" />;
});
