import { useEffect } from "react";

export function useDocumentColor(bodyColor: string) {
  useEffect(() => {
    const styles = window.document.documentElement.style;
    const previousColor = styles.backgroundColor;
    styles.backgroundColor = bodyColor;

    return () => {
      styles.backgroundColor = previousColor;
    };
  });
}
