import { useEffect, useLayoutEffect } from "react";
import { useLocation } from "wouter";

type TimestampedCoordniates = [ts: number, x: number, y: number];

const scrollPositions = new Map<string, TimestampedCoordniates>();

const neverRestore = new Set([
  "<root>",
  "/quick-start/keywords",
  "/quick-start/profiles",
  "/quick-start/target-numbers",
]);

/**
 * Handles scroll restoration on window.
 *
 * This hook behaves a little differently than the default browser scroll
 * restoration. This is due to limitations of Wouter (our router of choice)
 * as well the need to make the app feel more app-like.
 *
 * Every scroll position is remembered however the user has got to it (we
 * don't differentiate between new entries in browser history and back
 * navigation), but they are only restored if the user last visited the
 * location less than 60 minutes ago.
 */

export function useScrollRestoration() {
  const [p] = useLocation();

  // Standardise pathname and replace the root path with <root> for
  // easier debugging
  const pathname = p.replace(/\/$/, "") || "<root>";

  // Record scroll position for given pathname on pushState/replaceState.
  useEffect(() => {
    const handleEvent = () => {
      const x = window.scrollX;
      const y = window.scrollY;
      scrollPositions.set(pathname, [Date.now(), x, y]);
      console.info(`Set scroll position for '${pathname}' (x: ${x}, y: ${y}).`);
    };

    // These events are provided by Wouter. They are not native browser events!
    window.addEventListener("pushState", handleEvent);
    window.addEventListener("replaceState", handleEvent);

    return () => {
      window.removeEventListener("pushState", handleEvent);
      window.removeEventListener("replaceState", handleEvent);
    };
  }, [pathname]);

  // Restore scroll position if last visit was less than 60 minutes ago.
  useLayoutEffect(() => {
    const maxDiff = 60_000 * 60; // 60 minutes
    const coordinates = scrollPositions.get(pathname);

    if (coordinates) {
      if (!neverRestore.has(pathname)) {
        const now = Date.now();
        const [ts, x, y] = coordinates;

        if (now - ts < maxDiff) {
          window.scrollTo(x, y);
          console.info(
            `Restoring scroll position for '${pathname}' (x: ${x}, y: ${y}).`,
          );
        } else {
          window.scrollTo(0, 0);
          console.info(
            `Not restoring scroll position for '${pathname}'. Last visit >60 min ago.`,
          );
        }
      } else {
        window.scrollTo(0, 0);
        console.info(
          `Not restoring scroll position for '${pathname}'. Page is set never to restore.`,
        );
      }
    } else {
      window.scrollTo(0, 0);
      console.info(
        `Not restoring scroll position for '${pathname}'. Page not visited yet.`,
      );
    }
  }, [pathname]);
}
