import { createContext, useContext } from "react";
import { Session } from "@ory/client";
import { Pending, Success } from "@/lib/async-op";

export type SessionContextValue = {
  /**
   * If session holds Success<Session> it means the user is logged in. If it
   * holds Success<null>, it means that the user is using the app in local
   * only mode. The Pending value means that the app is currently in the state
   * of figuring our whether the user is or isn't logged in.
   */
  sessionResult: Success<Session> | Success<null> | Pending;
  session: Session | null;
  isLoggedIn: boolean;
  isRetrievingSession: boolean;
  logOut: () => void;
};

function noop() {
  console.warn("You have called a noop function.");
}

export const SessionContext = createContext<SessionContextValue>({
  sessionResult: new Pending(),
  session: null,
  isLoggedIn: false,
  isRetrievingSession: false,
  logOut: noop,
});

export function useSession() {
  return useContext(SessionContext);
}
