import { useEffect } from "react";

/**
 * This element prevents the default MacOS behaviour where a fullscreen window
 * gets minimised by pressing Escape.
 */
export function FullscreenDismissBlocker() {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return null;
}
