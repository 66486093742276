import * as css from "./style.css";
import { ordinal } from "../../lib/text";

function ArrowLeft(props: { className: string }) {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.24537e-07 12L20.5714 24L11.7195 12L20.5714 -8.99206e-07L5.24537e-07 12Z"
        className={props.className}
      />
    </svg>
  );
}

function ArrowRight(props: { className: string }) {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1431 12L0.571639 24L9.42359 12L0.57164 -8.99206e-07L21.1431 12Z"
        className={props.className}
      />
    </svg>
  );
}

export function ScrollControls(props: {
  scrollToPreviousSection?: () => () => void;
  scrollToNextSection?: () => () => void;
  scrollToSectionById: (id: string) => () => void;
  isFirstIntersecting: boolean;
  isOnlyLastIntersecting: boolean;
  isSectionIntersecting: (id: string) => boolean;
  sectionsIds: string[];
  theme?: "light" | "dark";
}) {
  return (
    <div className={css.controls({ theme: props.theme })}>
      {props.scrollToPreviousSection && (
        <button
          type="button"
          onClick={props.scrollToPreviousSection()}
          className={css.scrollButton({ disabled: props.isFirstIntersecting })}
        >
          <ArrowLeft className={css.arrow} />
        </button>
      )}

      <div className={css.visibilityIndicatorsContainer}>
        {props.sectionsIds.map((sectionId, index) => {
          const buttonVisibility =
            props.isSectionIntersecting(sectionId) ? "active" : "inactive";

          return (
            <button
              className={css.visibilityIndicatorButton}
              type="button"
              key={sectionId}
              onClick={props.scrollToSectionById(sectionId)}
              aria-label={`Scroll to ${ordinal(index + 1)} section`}
            >
              <div className={css.visibilityIndicator[buttonVisibility]} />
            </button>
          );
        })}
      </div>

      {props.scrollToNextSection && (
        <button
          type="button"
          onClick={props.scrollToNextSection()}
          className={css.scrollButton({
            disabled: props.isOnlyLastIntersecting,
          })}
        >
          <ArrowRight className={css.arrow} />
        </button>
      )}
    </div>
  );
}
