export class LookupMap<K, V> extends Map<K, V> {
  getOrThrow(key: K) {
    const value = this.get(key);

    if (value === undefined) {
      throw new Error(`Lookup Error: No value found at ${key}.`);
    }

    return value;
  }

  getOr<T>(key: K, fallback: T) {
    const value = this.get(key);

    if (value === undefined) {
      return fallback;
    }

    return value;
  }

  static fromObject<K extends string | number | symbol, V>(
    object: Record<K, V>,
  ) {
    return new LookupMap<K, V>(Object.entries(object) as Array<[K, V]>);
  }
}
