export type PictureProps = {
  media: { media: string; srcSet: string; width: number; height: number }[];
  default: { src: string; width: number; height: number };
};

export function Picture(props: PictureProps & { className?: string }) {
  return (
    <picture>
      {props.media.map((media) => {
        return <source key={media.media} {...media} />;
      })}
      <img className={props.className} alt="" {...props.default} />
    </picture>
  );
}
